.Home{
    width: 100%;
    max-width: 1100px;
    margin: 0px auto;
}
.Header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100px;
}
.Nav{
    display: flex;
}
.Nav li{
    cursor: pointer;
    color: #fff;
    font-size: 20px;
    margin-left: 40px;
}
.Menus{
    height: 100vh;
    background: #000;
    border-left: 1px solid #3D3D3D;
}
.MenuItems li{
    font-size: 20px;
    color: #fff;
    width: 80%;
    margin: 0px auto;
    height: 100px;
    line-height: 100px;
    border-bottom: 1px solid #3D3D3D;
}
.MenuButton{
    display: none;
}
.Nav li a,.MenuItems li a{
    text-decoration: none;
    color: #fff;
}
.CloseMenu{
    width: 64px;
    margin: 0px auto;
    margin-top: 40px;
}
.Introduce{
    padding: 150px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Introduce p a{
    color: #fff;
    text-decoration: underline;
}

.IntroduceContent h6{
    font-weight: bold;
    font-size: 36px;
    color: #fff;
}
.IntroduceContent p{
    font-size: 18px;
    line-height: 24px;
    color: #C0C0C0;
    padding: 40px 0px;
}
.IntroduceContent{
 
    width: 500px;
}
.IntroduceImage{
    width: 400px;
}
.LearnMoreButton{
    color: #fff;
    text-align: center;
    display: block;
    cursor: pointer;
    width: 200px;
    height: 48px;
    line-height: 48px;
    border: 1px solid #fff;
    border-radius: 4px;
    text-decoration: none;
}
.Why{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.WhyTitle{
    width: 40%;
    text-align: left;
    font-weight: bold;
    font-size: 36px;
    color: #fff;
}
.WhyImage{
    width: 40%;
    text-align: center;
}
.WhyItems{
    width: 60%;
}
.WhyItems li{
    border-bottom: 1px solid #3D3D3D;
    padding: 40px 0px;
}
.WhyItemTitle{
    align-items: center;
    display: flex;

    padding-bottom: 20px;
}
.WhyItemTitle p{

    color: #fff;
    font-size: 20px;
    margin-left: 10px;

}
.WhyItemDetail{

    color: #C0C0C0;

}
.Build{
    width: 100%;
}
.BuildTitle{
    width: 100%;
    color: #fff;
    text-align: center;
    font-size: 36px;
}
.BuildDescription{
    width: 80%;

    text-align: center;
    color: #C0C0C0;
    font-size: 20px;
    margin: 0px auto;
    margin-top: 60px;
}

.BuildButton{
    color: #fff;
    text-align: center;
    display: block;
    cursor: pointer;
    width: 200px;
    height: 48px;
    line-height: 48px;
    border: 1px solid #fff;
    border-radius: 4px;
    text-decoration: none;
    margin: 30px auto 0px;
    
}
.SocialLinks{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.TwitterIcon{
    width: 32px;
}
.DiscordIcon{
    width: 32px;
    margin-left: 20px;
}

.Tools{
    width: 100%;
    height: auto;
    border: 1px solid #3D3D3D;
    margin: 200px 0px;
}

.Grid{
    display: flex;
    justify-content: space-around;

}
.Grid li {
    width: calc(33% - 40px);
    height: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
}
.GridSplit{
    width: 100%;
    height: 1px;
    background: #3D3D3D;
}
.GridCenter{
    border-left: 1px solid #3D3D3D;
    border-right: 1px solid #3D3D3D;
}
.ToolsTitle{

    color: #fff;
    font-size: 36px;
}
.Grid li h6{
    width: 100%;
    text-align: left;
    font-size: 20px;
    color: #fff;
}
.Grid li p{
    text-align: left;
    color: #C0C0C0;
    margin-top: 10px;
    width: 100%;
}
.Footer{
    text-align: center;
    margin-top: 200px;
    width: 100%;
    color: #C0C0C0;
    border-top: 1px solid #3D3D3D;
    padding: 40px 0px;
}
.toolkitTitle{

    font-size: 16px;
    font-weight: bold;
    width: 100%;
    text-align: left;
    margin-top: 20px;
}
.toolkitTitle a{
    color: #fff;
}
.Grid a{
    color: #fff;
}


@media only screen and (max-width: 576px) {
    .toolkitTitle{
        text-align: center;
    }
    .Header{
        width: 90%;
        margin: 0px auto;
    }
    .Nav{
        display: none;
    }
    .MenuButton{
        display: block;
    }
    .Introduce{
        flex-direction:column-reverse;
        padding-top: 0px;
    }
    .IntroduceImage{
        width: 80%;
    }
    .IntroduceContent{
        width: 90%;
        margin: 20px auto 0px;

    }
    .Why{
        flex-direction:column;
    }
    .WhyTitle {
        width: 90%;
        margin: 0px auto;
    }
    .WhyItems{
        width: 90%;
    }
    .Grid{
        width: 90%;
        flex-direction:column;
        margin: 0px auto;
        border-top: 1px solid #3D3D3D;
        border-right: 1px solid #3D3D3D;
        border-left: 1px solid #3D3D3D;
    }
    .Grid li {
        text-align: center;
        width: 100%;
        padding: 0px;
        border-bottom: 1px solid #3D3D3D;
        padding: 20px 0px;
    }
    .Grid li h6{
        text-align: center;

    }
    .Grid li p{
        width: 90%;
    }

    .GridSplit{

        height: 0px;

    }

    .GridCenter{
        border: 0px;
    }

    .Tools{
        border: 0px;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .Footer{
        margin-top: 100px;
    }
    
   
}